
import { defineComponent, onMounted, ref, watch } from "vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import PlantaoDuvidaColumn from "@/util/table-columns/PlantaoDuvidaColumn";
import PlantaoDuvidaForm from "@/components/admin/plantao-duvidas/PlantaoDuvidaForm.vue";
import useList from "@/modules/useList";
import store from "@/store";
import Turma from "@/interfaces/Turma";
import DivisaoService from "@/services/auth/DivisaoService";
import BaseService from "@/services/admin/BaseService";
import Disciplina from "@/interfaces/Disciplina";
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';;

export default defineComponent({
  components: { PlantaoDuvidaForm },
  setup() {
    const $q = useQuasar();
    const turmas = ref<Turma[]>([]);
    const disciplinas = ref<Disciplina[]>([]);
    const {
      rows,
      loading,
      filter,
      columns,
      list,
      formatData,
      modalForm,
      openModalForm,
      confirmDelete,
    } = useList("plantao-duvidas", PlantaoDuvidaColumn);
    const isInternalUpdate = ref(false);

    const getDisciplinas = (): void => {
      loading.value = true;
      BaseService.list("disciplinas")
        .then((res) => {
          disciplinas.value = res.data;
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const getTurmas = (): void => {
      loading.value = true;
      if (store.getters.divisaoId) {
        DivisaoService.getTurmas(store.getters.divisaoId)
          .then((res) => {
            turmas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        BaseService.list("turmas")
          .then((res) => {
            turmas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    const copyLink = (values: any): void => {
      copyToClipboard(values.url)
        .then(() => {
          showNotify({
            type: "positive",
            message: "Link copiado com sucesso",
          });
        })
        .catch(() => {
          showNotify({
            type: "positive",
            message: "Falha ao copiar link da redação",
          });
        });
    };

    watch(rows, async (newRows, oldRows) => {
      if (isInternalUpdate.value) {
        // Reset the flag and exit the watch function
        isInternalUpdate.value = false;
        return;
      }

      if (store.getters.divisaoId) {
        if (newRows.length > 0) {
          const newValues = newRows.filter((row: any) => {
            if (row?.disciplina) {
              if (row?.disciplina.divisao_id === store.getters.divisaoId) {
                return row;
              }
            }
            if (row?.turma) {
              if (row.turma.divisao_id === store.getters.divisaoId) {
                return row;
              }
            }
          });

          isInternalUpdate.value = true;
          rows.value = newValues;
        }
      }
    });

    onMounted(() => {
      getTurmas();
      getDisciplinas();
    });

    return {
      rows,
      loading,
      filter,
      columns,
      list,
      modalForm,
      openModalForm,
      formatData,
      getTurmas,
      getDisciplinas,
      turmas,
      disciplinas,
      copyLink,
      confirmDelete,
    };
  },
});
